
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  // В данной переменной хранятся данные, которые пользователи вводят на клиенте. Сюда надо прикрутить скрипт на пыхе,
  // чтобы он отправлял данные из этой переменной на почту Фоминой / Мырксиной.
  data: () => ({
    name: '',
    email: '',
    question: ''
  }),
  methods: {
    async sendFormData() {
      const res = await fetch('ссылка на сервер', {
        method: 'POST',
        headers: { 
          'Content-type': 'application/json' 
        },
        body: JSON.stringify({
          name: this.name,
          email: this.email,
          question: this.question
        })
      }) 
    }
  }
})
