
import { defineComponent } from "@vue/runtime-core";
import Flicking from "@egjs/vue3-flicking";
import "@egjs/vue3-flicking/dist/flicking.css";


export default defineComponent({
  data: () => ({
    currentSlide: 0,
    alumnuses: [
    {
        image: 'ОверчукАЛ.png',
        name: 'Оверчук Алексей Логвинович',
        desciption: `
          Заместитель председателя <br/>Правительства Российской Федерации.
          <br/>
          <br/>
          С 2011 по 2020 год занимал должность заместителя  руководителя Федеральной 
          налоговой службы Российской Федерации.
          <br/>
          <br/> 
          Действительный государственный советник Российской Федерации 2-го класса. 
          <br/>
          <br/>
          В 1986 году окончил Московскую сельскохозяйственную академию имени  К.А. Тимирязева 
          по специальности «Экономическая кибернетика», кандидат экономических наук.
        `
      },
      {
        image: 'МаслаковаВВ.jpg',
        name: 'Маслакова Веста Владимировна',
        desciption: `
          Главный экономист Отдела мониторинга кредитных операций головного 
          офиса АО «Россельхозбанк», кандидат экономических наук по научной 
          специальности 08.00.12 «Бухгалтерский учет, статистика». 
          <br/>
          <br/>
          Выпускница РГАУ-МСХА имени К.А. Тимирязева 2014 года – бакалавриата 
          по направлению «Экономика», направленность «Финансы и кредит», 2016 
          года – магистратуры по направлению «Экономика», магистерской 
          программы «Статистическая бизнес-аналитика». Кандидатская диссертация 
          на тему «Статистический анализ эффективности инвестирования в 
          развитие сельского хозяйства России» была подготовлена на кафедре 
          статистики и кибернетики РГАУ-МСХА имени К.А. Тимирязева.
        `
      },
      {
        image: 'БобышевПП.jpg',
        name: 'Бобышев Петр Петрович',
        desciption: `
          Руководитель направления проектов дистанционного обучения и кадровых 
          сервисов в Департаменте информационных технологий города Москвы.
          <br/>
          <br/>
          Выпускник РГАУ – МСХА имени К.А. Тимирязева. С отличием закончил бакалавриат (2016 год), магистратуру (2018 год) по направлению «Информационные системы и технологии» и аспирантуру по направлению «Системный анализ, обработка и управление информацией (по отраслям)». Занимает должность директора проектов в ПАО «Ростелеком», осуществляет деятельность по направлению реинжиниринга архитектуры цифровых решений государственных сервисов, созданию и развитию информационных систем федерального масштаба.
        `
      },
      {
        image: 'ЖабинВЕ.jpg',
        name: 'Жабин Владислав Евгеньевич',
        desciption: `
          Выпускник Российского государственного аграрного университета – 
          МСХА имени К.А. Тимирязева, закончил бакалавриат по направлению 
          «Прикладная информатика» (2017 г.), магистратуру по направлению 
          «Информационные системы и технологии в бизнес-аналитике» (2019 г.). 
          Продолжил обучение в аспирантуре по направлению «Системный анализ, 
          управление и обработка информации».
          <br/>
          <br/>
          На первом курсе магистратуры прошел стажировку в одной из лидирующих 
          IT-компаний – «Dell EMC», с 2018 года по 2020 год работал ведущим
          инженером поддержания работоспособности критически важных систем
          «Сбербанк Онлайн», с февраля 2021 года – инженером-проектировщиком 
          в инновационном подразделении российской компании 3Logic Group в 
          Сколково, основным направлением которой является робототехника и 
          системы IoT для агросектора.
          <br/>
          <br/> 
          С 2022 года и по настоящее время является руководителем проектов 
          коммерческого рынка в компании-производителе российских IT-решений 
          3Logic. Профессиональная деятельность заключается в работе с заказчиками, 
          формировании технических заданий, подготовке архитектуры решений 
          IT-продукта, оценке эффективности существующих процессов, взаимодействии
          с бизнесом, направление импортозамещения IT-решений.
        `
      },
      {
        image: 'СусловаТА.png',
        name: 'Суслова Татьяна Александровна',
        desciption: `
          Директор Академии Бизнеса Ernst & Young (EY), бизнес-тренер, 
          консультант, фасилитатор. 
          <br/>
          <br/>
          Выпускница МСХА имени К.А. Тимирязева 2003 г. по специальности 
          «Бухгалтерский учет, анализ и аудит» и специализированных программ 
          обучения Humboldt University of Berlin и Wharton School, University 
          of Pennsylvania, кандидат экономических наук. Обладатель международных 
          дипломов и сертификатов FCCA, PMP, PSPO, PSM, победитель премии EMEIA 
          Advisory Excellence Awards 2018 в номинации «Фасилитатор года».
          <br/>
          <br/> 
          Преподавала на кафедре бухгалтерского учета и аудита в МСХА имени 
          К.А. Тимирязева, также работала в ЗАО «Делойт и Туш СНГ» ведущим 
          экспертом аудиторского отдела. 
          <br/>
          <br/>
          Сейчас возглавляет команду бизнес-тренеров Академии бизнеса EY, 
          в которой прошли обучение свыше 25 тысяч специалистов ведущих компаний. 
          Татьяна Суслова специализируется на развитии топ-менеджеров, успешно 
          работает с такими компаниями как «РЖД», «Евраз Холдинг», «Металлоинвест», 
          «Леруа Мерлен» в области стратегического и проектного менеджмента.
        `
      },
      

      {
        image: 'БарышниковаММ.png',
        name: 'Барышникова Мария Михайловна',
        desciption: `
          Заместитель директора департамента информационных технологий и интеллектуальных 
          транспортных систем Государственной компании "Российские автомобильные дороги".
          <br/>
          <br/>
          Кандидат экономических наук по специальности 08.00.12 «Бухгалтерский учет, 
          статистика», выпускница МСХА имени К.А. Тимирязева 2000 г. специальности 
          «Математические методы в экономике».
        `
      },

      {
        image: 'СергеевАВ.png',
        name: 'Сергеев Артур Владимирович',
        desciption: `
          Выпускник Российского государственного аграрного университета - МСХА имени К.А. 
          Тимирязева, в 2016 году с отличием окончил бакалавриат по направлению «Экономика», 
          направленность «Финансы и кредит». 
          <br/>
          <br/>
          Свою профессиональную деятельность начал в 2015 году в качестве экономиста АНО 
          «Экспертно-правовой центр «Право в экономике». 
          <br/>
          <br/>
          С 2018 года работал главным экспертом отдела централизованной надзорной аналитики 
          Департамента рынка ценных бумаг и товарного рынка Центрального Банка Российской Федерации.
          <br/>
          <br/>
          В 1 квартале 2019 года стал лауреатом ежеквартальной программы признания Банка 
          России «Мегасотрудник» за активное участие в разработке аналитических отчетов, 
          позволяющих эффективно осуществлять дистанционный надзор за профессиональными участниками 
          рынка ценных бумаг. 
          <br/>
          <br/>
          С 2021 года работает начальником отдела аналитики Департамента инфраструктуры 
          финансового рынка Банка России. Основные задачи заключаются в проведении актуальной 
          сложной аналитики по различным направлениям функционирования и развития финансового 
          рынка с целью обеспечения принятия руководством Банка России своевременных и обоснованных 
          решений, а также разработка и совершенствование скоринговых моделей в рамках осуществления 
          эффективного дистанционного надзора за инфраструктурными организациями финансового рынка.
        `
      },

      {
        image: 'ПрисяжныйМС.png',
        name: 'Присяжный Максим Сергеевич',
        desciption: `
          Выпускник Российского государственного аграрного университета – МСХА имени К.А. Тимирязева, 
          закончил бакалавриат по направлению «Бизнес-информатика» (2016 г.)  и магистратуру по 
          направлению «Информационные системы и технологии» (2018 г.). Результаты его магистерской 
          диссертации были высоко оценены на 20-ой Российской агропромышленной выставке «Золотая осень»: 
          за создание информационной системы для оценки эффективности и потенциала сотрудников при подборе 
          персонала он был награжден Серебряной медалью. 
          <br/
          <br/>
          На втором курсе бакалавриата прошел стажировку в одной из лидирующих российских 
          агрокомпаний «АгроТерра», а с 2014 года по июнь 2021 года – работал ведущим аналитиком 
          отдела развития информационных систем. 
          <br/>
          <br/>
          С июля 2021 года по настоящее время работает в ПАО «Сбербанк» главным аналитиком отдела 
          развития партнерской сети продукта «СберСпасибо». Основное направление профессиональной 
          деятельности заключается в сборе, анализе и подготовке функциональных требований, 
          тестировании и приемке нового IT-продукта, оценке эффективности существующих процессов, 
          взаимодействии с бизнесом, разработчиками, архитектором и техническими специалистами.
        `
      },
    ]
  }),
  methods: {
    nextSlide() {
      // @ts-ignore
      this.$refs.carousel.next()
    },
    prevSlide() {
      // @ts-ignore
      this.$refs.carousel.prev()
    }
  },
  mounted() {

  },
  components: {
    // Flicking,
  }
})
