import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/arrow-bottom.svg'


const _withScopeId = n => (_pushScopeId("data-v-00720c85"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "e-card" }
const _hoisted_2 = { class: "e-card__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "e-card__header e-card__header--without-icon",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('changeState')))
    }, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
      _createElementVNode("img", {
        class: _normalizeClass(["e-card__open-body-btn", _ctx.isOpen ? 'e-card__open-body-btn--top' : 'e-card__open-body-btn--bottom']),
        src: _imports_0
      }, null, 2)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["e-card__body", _ctx.isOpen ? 'e-card__body--opened' : 'e-card__body--closed'])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2)
  ]))
}