
import { defineComponent } from "@vue/runtime-core";
import Welcome from '../parts/landing/Welcome.vue'
import AboutUs from '../parts/landing/AboutUs.vue'
import PreparationDirections from "@/parts/landing/PreparationDirections.vue"
import CompaniesAndInternationPrograms from "@/parts/landing/CompaniesAndInternationPrograms.vue";
import QuestionForm from "@/parts/landing/QuestionForm.vue";
import Alumnuses from "@/parts/landing/Alumnuses.vue";
import Call from '@/parts/landing/Call.vue'
import Footer from '@/parts/landing/Footer.vue'


export default defineComponent({
  data: () => ({
    isHeader: false,
    navigationItems: [
      {
        name: 'О нас',
        goTo: 'about-us'
      },
      {
        name: 'Направления',
        goTo: 'directions'
      },
      {
        name: 'Выпускники',
        goTo: 'alumnuses'
      },
      {
        name: 'Контакты',
        goTo: 'contacts'
      },
    ]
  }),
  components: {
    Welcome,
    AboutUs,
    PreparationDirections,
    Futher: CompaniesAndInternationPrograms,
    QuestionForm,
    Alumnuses,
    Call,
    Footer,
  }
})
