
import { defineComponent } from "@vue/runtime-core";


export default defineComponent({
  props: [
    'title',
    'isOpen'
  ],
  data: () => ({
  }),
  methods: {
  },
  computed: {
  }
})
