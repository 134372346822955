<template>
  <div class="footer">
    <div class="footer__content">
      <div class="footer__subtitle">
        По всем вопросам о поступлении в Институт экономики и 
        управления АПК <br/> РГАУ-МСХА имени К.А Тимирязева 
        обращаться:
        <br/>
        <br/>
      </div>
      <div class="footer__subtitle">Фомина Татьяна Николаевна</div>
      <div class="footer__hint">Заместитель директора по практике и<br/> профориентационной работе </div>
      <div class="footer__hint">+7 916 792-74-24</div>
      <div class="footer__hint mb-20">t.fomina@rgau-msha.ru</div>

      <div class="footer__title">Институт экономики и управления АПК<br/> в социальных сетях:</div>
      <a 
        class="footer__link"
        href="https://vk.com/econom.timacad"
      >https://vk.com/econom.timacad </a>
      <a 
        class="footer__link"
        href="https://t.me/economtimacad"
      >https://t.me/economtimacad</a>
      <a 
        class="footer__link"
        href="https://www.youtube.com/@user-hv3oo2qi9z/videos"
      >https://www.youtube.com/@user-hv3oo2qi9z/videos</a>
    </div>
  </div>
</template>

<style scoped>
.footer {
  background: #F2F1ED;
  display: flex;
  justify-content: center;
}
.footer__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 1100px;
  padding: 40px 15px;
}
.footer__title {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 48px;
}
.footer__subtitle {
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 32px;
}
.footer__hint {
  font-weight: 400;
  font-size: 1rem;
  line-height: 32px;
}
.footer__paragraph {
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 32px;
}
.footer__link {
  margin: 20px 0;
}

.footer__link:hover{
  color: green;
}

.mb-20{
  margin-bottom: 20px;
}


@media (max-width: 500px) {
  .footer__title {
    font-size: 24px;
    line-height: 30px;
  }
  .footer__subtitle {
    font-size: 22px;
    line-height: 22px;
  }
  .footer__hint {
    font-size: 1rem;
    line-height: 20px;
  }
  .footer__paragraph {
    font-size: 1rem;
    line-height: 30px;
  }
}
</style>