
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  data: () => ({
    companies: [
      'Rectangle 547.png',
      'vtb 1.png',
      'Гленкор.png',
      'загружено 1.png',
      'лого-1.png',
      'лого-2.png',
      'лого-3.png',
      'лого-4.png',
      'лого-5.png',
      'лого-6.png',
      'лого-7.png',
      'лого.png',
      'Микоян-1.png',
      'Микоян-2.png',
      'Микоян.png',
    ],
    universites: [
      'Rectangle 547-1.png',
      'Rectangle 547.png',
      'Гленкор-1.png',
      'Гленкор.png',
      'лого-1.png',
      'лого-2.png',
      'лого-3.png',
      'лого-4.png',
      'лого-7.png',
      'лого-8.png',
      'лого-10.png',
      'лого-12.png',
      'лого-13.png',
      'лого-14.png',
      'лого-15.png',
      'лого.png',
      'Микоян-1.png',
      'Микоян-2.png',
      'фон.png',
      'logo_new_sm.png'
    ],
  }),
})
